import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { ReactComponent as ExportExcelIco } from "../../../assets/icons/export-excel.svg";
import Loading from "../../../components/Loading";
import { Button, Input } from "../../../components/UTControls";
import { branchHeaders, deleteDesc, successDesc } from "../../../constants/dictionary";
import { SaveData, handleData } from "../../../constants/handleData";
import { translation } from "../../../constants/translate";
import ActionBar from "../../../containers/ActionBar";
import Confirmation from "../../../containers/modals/Confirmation";
import { SortIcon, _generateTableHeader, tableStyle } from "../../../helpers/tableHelper";
import { del_branch, fetch_allbranch, find_branch } from "../../../redux/Branch/actions";

class index extends Component {
	state = {
		records: [],
		selectedRecord: {},
		vehicleType: "",
		search: "",
		isLoading: true,
		isShowConfirmation: false,
		confirmationDesc: deleteDesc,
		confirmationType: "DELETE",
		currentUrl: "/app/branches",
		dataTableColumns: [],
		allowAdd: false,
		allowEdit: false,
		allowDelete: false,
	};
	componentDidMount() {
		this.resetHandleData();
		// check if data already exists set2State but not fetch it
		const branch = handleData.Branch
		if (branch.records.length > 0) {
			this.setState({ records: branch.records, search: branch.filters.search, isLoading: false });
		} else {
			this.props.fetch_allbranch({ page: 1, rowsPerPage: 10000, searchText: this.state.search });
		}
		this.updatePermission();
	}
	componentDidUpdate(prevProps) {
		if (prevProps.permissionModuleList !== this.props.permissionModuleList) {
			this.updatePermission();
		}
		if (prevProps.allbranches !== this.props.allbranches) {
			this.setState({ records: this.props.allbranches, isLoading: false }, () => {
				const filters = { search: this.state.search }
				SaveData("Branch", filters, this.state.records)
			});
		}
	}
	resetHandleData = () => {
		handleData.Branch = {
			...handleData.Branch,
			path: "list",
			data: {}
		};
	};
	updatePermission = () => {
		this.setState({
			allowAdd: this.props.permissionModuleList[this.props.actionName]?.moduleList.ADD,
			allowEdit: this.props.permissionModuleList[this.props.actionName]?.moduleList.EDIT,
			allowDelete: this.props.permissionModuleList[this.props.actionName]?.moduleList.DELETE
		}, () => {
			let permission = this.handleLayoutPermission();
			this.setState({ dataTableColumns: _generateTableHeader(branchHeaders, permission.actions, permission.events) });
		});
	};
	handleLayoutPermission = () => {
		const { allowEdit, allowDelete } = this.state;
		const actions = ["view"];
		const events = [this.handleView];

		if (allowEdit) {
			actions.push("edit");
			events.push(this.handleEdit);
		}
		if (allowDelete) {
			actions.push("delete");
			events.push(this.handleTrigger);
		}
		return { actions, events };
	};
	handleAdd = () => {
		handleData.Branch = { ...handleData.Branch, path: 'add' };
		this.props.history.push(`${this.state.currentUrl}/add`);
	};
	handleView = (record) => {
		const path = `view/${record.value}`;
		handleData.Branch = { ...handleData.Branch, path: path };
		this.handleRecordOperation(record, path);
	};
	handleEdit = (record) => {
		const path = `edit/${record.value}`;
		handleData.Branch = { ...handleData.Branch, path: path };
		this.handleRecordOperation(record, path);
	};
	handleRecordOperation = (record, path) => {
		this.props.find_branch({ branch: record });
		this.props.history.push(`${this.state.currentUrl}/${path}`);
	};
	handleDelete = () => {
		this.setState({ isShowConfirmation: false });
		const { selectedRecord } = this.state;
		this.props.del_branch(selectedRecord?.value, (acknowledge, message) => {
			if (acknowledge) {
				this.props.fetch_allbranch({ page: 1, rowsPerPage: 10000, searchText: this.state.search });
				this.setState({ isShowConfirmation: true, confirmationType: "SUCCESS", confirmationDesc: successDesc });
			} else {
				alert(message);
			}
		});
	};
	handleTrigger = (record = {}) => {
		this.setState({ isShowConfirmation: !this.state.isShowConfirmation }, () => {
			setTimeout(() => {
				this.setState({ confirmationType: "DELETE", confirmationDesc: deleteDesc, selectedRecord: record });
			}, 200);
		});
	};
	onChange = (e) => { this.setState({ [e.target.name]: e.target.value }) };
	onKeyPress = (e) => {
		if (e.key === "Enter") {
			this.setState({ isLoading: true });
			this.props.fetch_allbranch({ page: 1, rowsPerPage: 10000, searchText: this.state.search });
		}
	};

	render() {
		return (
			<div>
				<Confirmation
					handleClose={this.handleTrigger}
					handleConfirm={this.handleDelete}
					handleReject={this.handleTrigger}
					isShow={this.state.isShowConfirmation}
					confirmType={this.state.confirmationType}
					desc={this.state.confirmationDesc}
				/>
				<ActionBar title={this.props.actionName}>
					<Input
						name="search"
						value={this.state.search}
						placeholder={translation["searchBranch"]}
						rightIcon={faSearch}
						onChange={this.onChange}
						onKeyPress={this.onKeyPress}
					/>
					<div className="ut-export-button-wrapper" onClick={() => { this._excelExport.save(); }} >
						<div> <ExportExcelIco /> </div>
						<span className="ut-export-button">EXPORT AS EXCEL</span>
					</div>
					<Button name={translation["REFRESH"]} buttonStyle="filled"
						onClick={() => {
							this.setState({ isLoading: true, records: [], search: '' });
							this.props.fetch_allbranch({ page: 1, rowsPerPage: 10000});
						}}
					/>
					{this.state.allowAdd && (
						<Button name={translation["ADD NEW"]} color="success" size="md" buttonStyle="filled" onClick={this.handleAdd} />
					)}
				</ActionBar>
				<ExcelExport
					data={this.state.records}
					ref={(excel) => {
						this._excelExport = excel;
					}}
					fileName={`Branches.xlsx`}
					filterable={true}
				>
					<ExcelExportColumn field="no" title="No" />
					<ExcelExportColumn field="label" title="Branch" />
				</ExcelExport>
				<DataTable
					fixedHeader
					noDataComponent={<span style={{ margin: "20px 0px" }}>គ្មានទិន្នន័យ</span>}
					fixedHeaderScrollHeight="calc(100vh - 220px)"
					noHeader
					pointerOnHover
					striped
					highlightOnHover
					data={this.state.records}
					columns={this.state.dataTableColumns}
					sortIcon={<SortIcon />}
					customStyles={tableStyle}
					progressPending={this.state.isLoading}
					progressComponent={<Loading />}
					pagination={true}
					paginationPerPage={100}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	allbranches: state.Branch.allbranches,
	actionName: state.Navs.actionName,

	permissionModuleList: state.Layout.permissionModuleList,
});

export default connect(mapStateToProps, { fetch_allbranch, del_branch, find_branch })(index);
