import { faSearch } from "@fortawesome/free-solid-svg-icons";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import Loading from "../../../components/Loading";
import { Button, Input } from "../../../components/UTControls";
import { trackingHeaders } from "../../../constants/dictionary";
import { ResetRecords, SaveData, handleData } from "../../../constants/handleData";
import { translation } from "../../../constants/translate";
import ActionBar from "../../../containers/ActionBar";
import ArrivalConfirmation from "../../../containers/modals/ArrivalConfirmation";
import CancelConfirmation from "../../../containers/modals/CancelConfirmation";
import UpdateDestination from "../../../containers/modals/UpdateDestination";
import { SortIcon, _generateTableHeader, customSort, tableStyle } from "../../../helpers/tableHelper";
import { fetch_allbranch, fetch_branch } from "../../../redux/Branch/actions";
import { cancel_tracking, fetch_tracking, update_arrival, update_passing_destination, update_passing_start, update_passing_stop } from "../../../redux/Tracking/actions";

class index extends Component {
	state = {
		records: [],
		selectedRecord: {},
		passing: [],
		search: "",
		isLoading: true,
		isShowUpdateDestination: false,
		isShowArrivalConfirmation: false,
		isShowCancelConfirmation: false,
		confirmationDesc: "",
		remark: "",
		currentUrl: "/app/tracking",
		dataTableColumns: [],
		allowAdd: this.props.permissionModuleList[this.props.actionName]?.moduleList.ADD,
		allowEdit: this.props.permissionModuleList[this.props.actionName]?.moduleList.EDIT,
		allowDelete: this.props.permissionModuleList[this.props.actionName]?.moduleList.DELETE,
	};

	componentDidMount = () => {
		handleData.Tracking = {
			...handleData.Tracking,
			path: "list",
			data: {}
		};
		// check if data already exists set2State but not fetch it
		const tracking = handleData.Tracking
		if (tracking.records.length > 0) {
			this.setState({ records: tracking.records, search: tracking.filters.search, isLoading: false });
		} else {
			this.props.fetch_tracking({ page: 1, rowsPerPage: 10000 });
			this.props.fetch_branch({ page: 1, rowsPerPage: 10000, searchText: this.state.search });
			this.props.fetch_allbranch({ page: 1, rowsPerPage: 10000, searchText: this.state.search });
		}
		this.setPermissions(true)
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.permissionModuleList !== this.props.permissionModuleList) {
			this.setPermissions(false)
		}
		if (prevProps.trackings !== this.props.trackings) {
			this.setState({ records: this.props.trackings, isLoading: false }, () => {
				const filters = { search: this.state.search }
				SaveData("Tracking", filters, this.state.records)
			});
		}
	}
	setPermissions = (isAdd) => {
		this.setState(
			(prevState) => {
				let newState = {
					allowEdit: this.props.permissionModuleList[this.props.actionName]?.moduleList.EDIT,
					allowDelete: this.props.permissionModuleList[this.props.actionName]?.moduleList.DELETE
				};
				if (!isAdd) {
					newState.allowAdd = this.props.permissionModuleList[this.props.actionName]?.moduleList.ADD;
				} else newState.allowAdd = true;
				return newState;
			}, () => {
				let permission = this.handleLayoutPermission();
				this.setState({ dataTableColumns: _generateTableHeader(trackingHeaders, permission.actions, permission.events) });
			});
	}
	handleLayoutPermission = () => {
		let actions = ["tracking"],
			events = [this.handleTracking];

		if (this.state.allowEdit) {
			actions.push("edit tracking");
			events.push(this.handleEditTracking);
		}

		if (this.state.allowDelete) {
			actions.push("cancel");
			events.push(this.handleCancel);
		}

		return { actions, events };
	};

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	onKeyPress = (e) => {
		if (e.key === "Enter") {
			this.setState({ isLoading: true });
			this.props.fetch_tracking({ page: 1, rowsPerPage: 10000, vehicleName: this.state.search });
		}
	};
	handleAdd = () => {
		handleData.Tracking = { ...handleData.Tracking, path: "add" }
		this.props.history.push(`${this.state.currentUrl}/add`);
	};
	handleTracking = (record, action) => {
		this.setState({ selectedRecord: record, passing: action, isShowArrivalConfirmation: true });
	};
	handleEditTracking = (record) => {
		this.setState({ selectedRecord: record, passing: record.passing.filter((pass) => pass.state < 1), isShowUpdateDestination: true });
	};
	handleCancel = (record) => {
		this.setState({ selectedRecord: record, isShowCancelConfirmation: true });
	};
	handleCheckBranch = (destination) => {
		let filteredBranch = this.props.branches.filter((branch) => branch.label === destination);
		return filteredBranch.length > 0 ? true : false;
	};
	handleUpdateDestination = (selected) => {
		if (this.handleCheckBranch(selected.label)) {
			let newPassing = {
				id: this.state.passing[0].value,
				branchToId: selected.value,
			};
			this.props.update_passing_destination(newPassing, (acknowledge, message) => {
				if (acknowledge) {
					ResetRecords("Tracking")
					this.props.fetch_tracking({ page: 1, rowsPerPage: 10000 });
					this.handleTriggerUpdateDestination();
				} else alert(message);
			});
		} else alert("លោកអ្នកមិនអាចប្រើប្រាស់ទីតាំងនេះបានទេ");
	};

	handleArrival = () => {
		if (this.handleCheckBranch(this.state.passing.length === 0 ? this.state.selectedRecord?.destination.label : this.state.passing[0].label)) {
			let arrival = {
				branchId: this.state.selectedRecord?.origin.value,
				vehicleId: this.state.selectedRecord?.vehicle.value,
				branchToBId: this.state.passing.length === 0 ? this.state.selectedRecord?.destination.value : this.state.passing[0].branchId,
				branchToId: this.state.passing.length === 0 ? this.state.selectedRecord?.destination.value : this.state.passing[0].branchId,
				remartStop: this.state.remark,
			};
			if (this.state.passing.length === 0) {
				this.props.update_arrival(arrival, (acknowledge, message) => {
					if (acknowledge) {
						this.props.fetch_tracking({ page: 1, rowsPerPage: 10000 });
						this.setState({ isShowArrivalConfirmation: !this.state.isShowArrivalConfirmation, remark: "" });
					} else alert(message);
				});
			} else if (this.state.passing[0].state === 1) {
				this.props.update_passing_start(arrival, (acknowledge, message) => {
					if (acknowledge) {
						this.props.fetch_tracking({ page: 1, rowsPerPage: 10000 });
						this.setState({ isShowArrivalConfirmation: !this.state.isShowArrivalConfirmation, remark: "" });
					} else alert(message);
				});
			} else {
				this.props.update_passing_stop(arrival, (acknowledge, message) => {
					if (acknowledge) {
						this.props.fetch_tracking({ page: 1, rowsPerPage: 10000 });
						this.setState({ isShowArrivalConfirmation: !this.state.isShowArrivalConfirmation, remark: "" });
					} else alert(message);
				});
			}
		} else alert("លោកអ្នកមិនអាចប្រើប្រាស់ទីតាំងនេះបានទេ");
	};

	handleTrigger = (record = {}) => {
		this.setState({ isShowArrivalConfirmation: !this.state.isShowArrivalConfirmation }, () => {
			setTimeout(() => {
				this.setState({ selectedRecord: record, passing: [], remark: "" });
			}, 100);
		});
	};

	handleTriggerCancel = (record = {}) => {
		this.setState({ isShowCancelConfirmation: !this.state.isShowCancelConfirmation });
	};

	handleTriggerUpdateDestination = (record = {}) => {
		this.setState({ selectedRecord: record, passing: [], isShowUpdateDestination: !this.state.isShowUpdateDestination });
	};

	render() {
		return (
			<div>
				<ActionBar title={this.props.actionName}>
					<Input
						name="search"
						value={this.state.search}
						placeholder={translation["searchCar"]}
						rightIcon={faSearch}
						onChange={this.onChange}
						onKeyPress={this.onKeyPress}
					/>
					<Button name={translation["REFRESH"]} buttonStyle="filled"
						onClick={() => {
							this.setState({ isLoading: true, records: [], search: '' });
							this.props.fetch_tracking({ page: 1, rowsPerPage: 10000 });
						}}
					/>
					{this.state.allowAdd && (
						<Button name={translation["ADD NEW"]} color="success" size="md" buttonStyle="filled" onClick={this.handleAdd} />
					)}
				</ActionBar>
				<UpdateDestination
					destination={this.state.passing.length && this.state.passing[0]}
					isShow={this.state.isShowUpdateDestination}
					handleConfirm={this.handleUpdateDestination}
					handleReject={this.handleTriggerUpdateDestination}
				/>
				<CancelConfirmation
					isShow={this.state.isShowCancelConfirmation}
					handleConfirm={() => {
						this.props.cancel_tracking({ id: this.state.selectedRecord.value }, (acknowledge, message) => {
							if (acknowledge) {
								this.props.fetch_tracking({ page: 1, rowsPerPage: 10000 });
								this.handleTriggerCancel();
							} else alert(message);
						});
					}}
					handleReject={this.handleTriggerCancel}
				/>
				<ArrivalConfirmation
					passingState={this.state.passing.length}
					onChange={this.onChange}
					handleConfirm={this.handleArrival}
					handleReject={this.handleTrigger}
					isShow={this.state.isShowArrivalConfirmation}
					desc={`តើឡាន ${this.state.selectedRecord?.vehicle?.label} ${this.state.passing.length === 0 ? "មកដល់" : this.state.passing[0].state === 1 ? "ចាកចេញ" : "មកដល់"
						} ${this.state.passing.length === 0 ? this.state.selectedRecord?.destination?.label : this.state.passing[0]?.label} ហើយមែនទេ?`}
				/>
				<DataTable
					fixedHeader
					noDataComponent={<span style={{ margin: "20px 0px" }}>គ្មានទិន្នន័យ</span>}
					fixedHeaderScrollHeight="calc(100vh - 220px)"
					noHeader
					pointerOnHover
					striped
					highlightOnHover
					data={this.state.records}
					columns={this.state.dataTableColumns}
					sortIcon={<SortIcon />}
					customStyles={tableStyle}
					progressPending={this.state.isLoading}
					progressComponent={<Loading />}
					sortFunction={customSort}
					pagination={true}
					paginationPerPage={100}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	branches: state.Branch.branches,
	trackings: state.Tracking.trackings,
	actionName: state.Navs.actionName,
	permissionModuleList: state.Layout.permissionModuleList,
});

export default connect(mapStateToProps, {
	fetch_branch,
	fetch_allbranch,
	fetch_tracking,
	update_arrival,
	update_passing_stop,
	update_passing_start,
	update_passing_destination,
	cancel_tracking,
})(index);
