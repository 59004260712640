import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { GetPath, handleData } from "../../../constants/handleData";
import { ChangeSidebar } from "../../../redux/Navs/actions";
import { fetch_user } from "../../../redux/User/actions";
import { fetch_vehicle_type } from "../../../redux/VehicleType/actions";
import AddEdit from "./addEdit";
import List from "./list";

function Branch({ match, ChangeSidebar, fetch_vehicle_type, fetch_user }) {

   GetPath("Branch", "branches/")
   fetch_vehicle_type({ page: 1, rowsPerPage: 10000 });
   fetch_user({ page: 1, rowsPerPage: 10000 });
   ChangeSidebar({ currentPage: match.url, actionName: "Branches" });

   return (
      <div className="ut-container">
         <Switch>
            <Redirect exact from={`${match.url}`} to={`${match.url}/${handleData.Branch.path}`} />
            <Route path={`${match.url}/list`} component={List} />
            <Route path={`${match.url}/add`} component={AddEdit} />
            <Route path={`${match.url}/view/:id`} component={AddEdit} />
            <Route path={`${match.url}/edit/:id`} component={AddEdit} />
         </Switch>
      </div>
   );
}

const mapStateToProps = (state) => ({
   //
});

export default connect(mapStateToProps, { ChangeSidebar, fetch_vehicle_type, fetch_user })(Branch);
