import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { GetPath, handleData } from "../../../../constants/handleData";
import { fetch_branch } from "../../../../redux/Branch/actions";
import { ChangeSettingSidebar } from "../../../../redux/Navs/actions";
import { fetch_systemRole } from "../../../../redux/SystemRole/actions";
import AddReset from "./add-reset";
import AddEdit from "./addEdit";
import List from "./list";

class index extends React.Component {
	state = {};

	componentDidMount = () => {
		GetPath("User", "users/")
		handleData.Setting = { path: "users" };
		this.props.fetch_branch({ page: 1, rowsPerPage: 10000 });
		this.props.fetch_systemRole({ page: 1, rowsPerPage: 10000 });
		this.props.ChangeSettingSidebar({ currentSetting: this.props.match.url, actionName: "Users" });
	};

	render() {
		const { match } = this.props;
		return (
			<Switch>
				<Redirect exact from={`${match.url}`} to={`${match.url}/${handleData.User.path}`} />
				<Route exact path={`${match.url}/list`} component={List} />
				<Route exact path={`${match.url}/add`} component={AddEdit} />
				<Route exact path={`${match.url}/edit/:id`} component={AddEdit} />
				<Route exact path={`${match.url}/reset/:id`} component={AddReset} />
			</Switch>
		);
	}
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { ChangeSettingSidebar, fetch_branch, fetch_systemRole })(index);
